const backendDomain = process.env.REACT_APP_BACKENDDOMAIN;
// const backendDomain = "http://localhost:5000";
const summaryAPI = {
  /*------Common Routes------*/
  common: {
    signUP: {
      method: "POST",
      url: `${backendDomain}/signup`,
    },
    logIn: {
      method: "POST",
      url: `${backendDomain}/login`,
    },
    userDetails: {
      method: "GET",
      url: `${backendDomain}/user-details`,
    },
    updateUser: {
      method: "PUT",
      url: `${backendDomain}/update-user`,
    },
    logout: {
      method: "GET",
      url: `${backendDomain}/logout`,
    },
    getAllProducts: {
      method: "GET",
      url: `${backendDomain}/product`,
    },
    getProductById: {
      method: "GET",
      url: `${backendDomain}/product`,
    },
  },
  admin: {
    /*------Admin------ */
    getAllUser: {
      method: "GET",
      url: `${backendDomain}/admin/all-users`,
    },
    updateUser: {
      method: "PUT",
      url: `${backendDomain}/admin/update-user`,
    },
    deleteUser: {
      method: "DELETE",
      url: `${backendDomain}/admin/delete-user`,
    },

    /*------Admin products------ */
    getAllProducts: {
      method: "GET",
      url: `${backendDomain}/admin/product`,
    },
    getProductById: {
      method: "GET",
      url: `${backendDomain}/admin/product`,
    },
    createProduct: {
      method: "POST",
      url: `${backendDomain}/admin/product`,
    },
    updateProduct: {
      method: "PUT",
      url: `${backendDomain}/admin/product`,
    },
    deleteProduct: {
      method: "DELETE",
      url: `${backendDomain}/admin/product`,
    },
    deleteProductPDF: {
      method: "DELETE",
      url: `${backendDomain}/admin/product`,
    },
  },
};

export default summaryAPI;
