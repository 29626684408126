import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "../styles/pages/career.module.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
// import uploadImage from "../utils/uploadImage";
import uploadImageToFirebase from "../utils/uploadImageToFirebase";

function Career() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState("other");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result = await uploadImageToFirebase(file);
        setFileUrl(result.secure_url);
        setFileName(file.name);
        setFileType(file.type.startsWith("image/") ? "image" : "other");
        toast.success("File uploaded successfully!");
      } catch (error) {
        toast.error("Failed to upload file.");
        e.target.value = "";
      } finally {
        setIsUploading(false);
      }
    }
  };

  const onSubmit = async (data) => {
    if (!fileUrl) {
      toast.error("Please upload a file before submitting.");
      return;
    }

    setIsLoading(true);

    const templateParams = {
      ...data,
      fileUrl,
      fileType,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_KEY,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      );
      reset();
      setFileUrl(null);
      setFileName(null);
      setFileType("other");
      toast.success("Email Sent Successfully");
    } catch (error) {
      toast.error("Failed To Send Email");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name:</label>
            <input
              {...register("name", { required: "Name is required" })}
              type="text"
              placeholder="Enter your name"
              id="name"
            />
            {errors.name && (
              <p className={styles.error}>{errors.name.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="number">Phone Number:</label>
            <input
              {...register("number", {
                required: "Phone number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Phone number must be exactly 10 digits",
                },
              })}
              type="text"
              id="number"
              placeholder="Enter your contact"
            />
            {errors.number && (
              <p className={styles.error}>{errors.number.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email:</label>
            <input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Invalid email address",
                },
              })}
              type="email"
              placeholder="Enter your email"
              id="email"
            />
            {errors.email && (
              <p className={styles.error}>{errors.email.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="file">File:</label>
            <input
              {...register("file", { required: "File is required" })}
              type="file"
              onChange={handleFileChange}
              id="file"
              // accept="image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/zip,application/x-rar-compressed"
            />
            {errors.file && (
              <p className={styles.error}>{errors.file.message}</p>
            )}
            {isUploading && <p>Uploading file...</p>}
            {fileName && <p>Selected file: {fileName}</p>}
          </div>
          <div className={styles.formGroup}>
            <button type="submit" disabled={isLoading || isUploading}>
              {isLoading ? "Sending..." : isUploading ? "Uploading..." : "Send"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Career;
