import React from "react";
import styles from "../../styles/components/about/whyus.module.css";

function WhyUsCom() {
  const visionPoints = [
    {
      title: "Patient-Centric Innovation",
      description:
        "We place patients at the core of our endeavors. Our vision is to develop groundbreaking pharmaceutical solutions that enhance lives, alleviate suffering, and promote holistic health.",
    },
    {
      title: "Global Reach, Local Impact",
      description:
        "We aspire to be a global force for good. By collaborating with healthcare professionals, governments, and communities, we aim to improve access to quality medicines, eradicate diseases, and elevate public health.",
    },
    {
      title: "Scientific Excellence",
      description:
        "Our pursuit of knowledge fuels our progress. We invest in cutting-edge research, clinical trials, and novel drug development. Through science, we unravel mysteries and redefine healthcare norms.",
    },
    {
      title: "Ethical Integrity",
      description:
        "Integrity guides our actions. We uphold the highest ethical standards, ensuring transparency, safety, and trust. Our commitment extends beyond profit to societal well-being.",
    },
    {
      title: "Innovation Beyond Medicine",
      description:
        "We embrace innovation as a catalyst. Our vision extends beyond pharmaceuticals to digital health, personalized medicine, and sustainable practices. By pushing boundaries, we empower patients and healthcare providers.",
    },
    {
      title: "Collaboration and Diversity",
      description:
        "We celebrate diversity as our strength. Together, we foster an inclusive environment where diverse minds converge, collaborate, and create solutions that transform lives.",
    },
    {
      title: "Sustainability and Stewardship",
      description:
        "Our vision extends beyond today. We are stewards of the environment, committed to sustainable practices. From eco-friendly packaging to responsible manufacturing, we strive for a healthier planet.",
    },
  ];

  return (
    <div className={styles.visionPoints}>
      {visionPoints.map((point, index) => (
        <div key={index} className={styles.visionPoint}>
          <h2 className={styles.visionTitle}>{point.title}</h2>
          <p className={styles.visionDescription}>{point.description}</p>
        </div>
      ))}
      <p className={styles.conclusion}>
        Join us on this transformative journey. Together, we can build a
        healthier, brighter future one where health knows no limits.
      </p>
    </div>
  );
}

export default WhyUsCom;
