import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import styles from "../../styles/pages/admin/adminPanel.module.css";
import { toast } from "react-toastify";
import summaryAPI from "../../utils/summaryAPI";
import Loader from "../../components/common/Loader";
import { useOutletContext } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import uploadImage from "../../utils/uploadImage";

function AdminPanel() {
  const { handleApiError } = useOutletContext();
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newProduct, setNewProduct] = useState({
    name: "",
    images: [],
    file: null,
    pdf: null,
  });
  const [editingProduct, setEditingProduct] = useState(null);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${summaryAPI.admin.getAllProducts.url}`,
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setProducts(response.data);
    } catch (err) {
      handleApiError(err);
      setError("Failed to fetch products. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [token, handleApiError]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  async function createProduct(e) {
    e.preventDefault();

    toast.promise(
      (async () => {
        const formData = new FormData();
        formData.append("name", newProduct.name);

        // Handle image upload
        if (newProduct.file) {
          try {
            const uploadedImage = await uploadImage(newProduct.file);
            formData.append(
              "images",
              JSON.stringify([uploadedImage.secure_url])
            );
          } catch (error) {
            throw new Error("Failed to upload image: " + error.message);
          }
        }

        // Handle PDF
        if (newProduct.pdf) {
          formData.append("pdf", newProduct.pdf);
        }

        const response = await axios.post(
          `${summaryAPI.admin.createProduct.url}`,
          formData,
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        await fetchProducts();
        e.target.reset();
        setNewProduct({ name: "", images: [], file: null, pdf: null });
        return response.data;
      })(),
      {
        pending: "Creating product...",
        success: "Product created successfully!",
        error: "Failed to create product. Please try again.",
      }
    );
  }

  async function updateProduct(e) {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", editingProduct.name);

      // Handle image
      if (editingProduct.file) {
        try {
          const uploadedImage = await uploadImage(editingProduct.file);
          formData.append("images", JSON.stringify([uploadedImage.secure_url]));
        } catch (error) {
          throw new Error("Failed to upload image: " + error.message);
        }
      } else if (editingProduct.images && editingProduct.images.length > 0) {
        // If no new image is uploaded, keep the existing image URL
        formData.append("images", JSON.stringify(editingProduct.images));
      }

      // Handle PDF
      if (editingProduct.pdf instanceof File) {
        formData.append("pdf", editingProduct.pdf);
      }

      await axios.put(
        `${summaryAPI.admin.updateProduct.url}/${editingProduct._id}`,
        formData,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Product updated successfully");
      fetchProducts();
      setEditingProduct(null);
    } catch (err) {
      setError("Failed to update product. Please try again.");
    }
  }

  function deleteProduct(id) {
    confirmAlert({
      title: "Confirm !",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await axios.delete(
                `${summaryAPI.admin.deleteProduct.url}/${id}`,
                {
                  withCredentials: true,
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              toast.success("Product deleted successfully");
              fetchProducts();
            } catch (err) {
              setError("Failed to delete product. Please try again.");
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Deletion cancelled"),
        },
      ],
    });
  }

  async function deletePDF(id) {
    try {
      await axios.delete(`${summaryAPI.admin.deleteProductPDF.url}/${id}/pdf`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("PDF deleted successfully");
      fetchProducts();
    } catch (err) {
      setError("Failed to delete PDF. Please try again.");
    }
  }

  function downloadPDF(id, filename) {
    window.open(filename, "_blank");
  }

  if (loading)
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  if (error) return <div className={styles.error}>{error}</div>;

  return (
    <div className={styles.adminPanel}>
      <h1>Admin Panel</h1>

      <form onSubmit={createProduct} className={styles.form}>
        <input
          type="text"
          value={newProduct.name}
          onChange={(e) =>
            setNewProduct({ ...newProduct, name: e.target.value })
          }
          placeholder="Product Name"
          required
        />
        <div className={styles.formGroup}>
          <label htmlFor="images">Images:-</label>
          <input
            type="file"
            onChange={(e) =>
              setNewProduct({ ...newProduct, file: e.target.files[0] })
            }
            accept="image/*"
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="pdf">PDF:-</label>
          <input
            type="file"
            onChange={(e) =>
              setNewProduct({ ...newProduct, pdf: e.target.files[0] })
            }
            accept=".pdf"
          />
        </div>
        <button type="submit">Create Product</button>
      </form>

      <div className={styles.productList}>
        {products.map((product) => (
          <div key={product._id} className={styles.productItem}>
            {editingProduct && editingProduct._id === product._id ? (
              <form onSubmit={updateProduct} className={styles.form}>
                <input
                  type="text"
                  value={editingProduct.name}
                  onChange={(e) =>
                    setEditingProduct({
                      ...editingProduct,
                      name: e.target.value,
                    })
                  }
                  required
                />
                <div className={styles.formGroup}>
                  <label htmlFor="images">Images:-</label>
                  <input
                    type="file"
                    onChange={(e) =>
                      setEditingProduct({
                        ...editingProduct,
                        file: e.target.files[0],
                      })
                    }
                    accept="image/*"
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="pdf">PDF:-</label>
                  <input
                    type="file"
                    onChange={(e) =>
                      setEditingProduct({
                        ...editingProduct,
                        pdf: e.target.files[0],
                      })
                    }
                    accept=".pdf"
                  />
                </div>
                <button type="submit">Update</button>
                <button type="button" onClick={() => setEditingProduct(null)}>
                  Cancel
                </button>
              </form>
            ) : (
              <>
                <div className={styles.imgCon}>
                  <img
                    src={JSON.parse(product.images[0])[0]}
                    alt={product.name}
                    className={styles.productImage}
                  />
                </div>
                <h3>{product.name}</h3>

                <div className={styles.btnCon}>
                  <button onClick={() => setEditingProduct(product)}>
                    Edit
                  </button>
                  <button
                    className={styles.deleteBtn}
                    onClick={() => deleteProduct(product._id)}
                  >
                    Delete
                  </button>
                  {product.pdf && (
                    <>
                      <div className={styles.btnCon2}>
                        <button
                          onClick={() =>
                            downloadPDF(product._id, product.pdf.path)
                          }
                        >
                          Download PDF
                        </button>
                        <button
                          className={styles.deleteBtn}
                          onClick={() => deletePDF(product._id)}
                        >
                          Delete PDF
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminPanel;
