import React, { useEffect, useState } from "react";
import styles from "../../styles/components/common/moveToTop.module.css";

function MoveToTop() {
  const [scroll, setScroll] = useState(window.scrollY);

  function moveToTop() {
    window.scroll(0, 0);
  }

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  return (
    <>
      {scroll > 350 && (
        <div className={styles.moveBtn} onClick={moveToTop}>
          <i className="fa-solid fa-arrow-up"></i>
        </div>
      )}
    </>
  );
}

export default MoveToTop;
