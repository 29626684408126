import React from "react";
import styles from "../../styles/components/about/whyus.module.css";
import WhyUsCom from "./WhyUsCom";

function WhyUs() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.mainTitle}>
          Vision - Advancing Health, Enriching Lives
        </h1>
        <p className={styles.introduction}>
          At ALTERx Pharmaceuticals, we envision a future where health
          transcends boundaries and every individual enjoys well-being. Our
          unwavering commitment drives us to create a positive impact through
          innovation, compassion, and excellence. Our vision encompasses the
          following:
        </p>
        <WhyUsCom />
      </div>
    </section>
  );
}

export default WhyUs;
