export function searchDetails(query) {
  return fetch(`${process.env.REACT_APP_BACKENDDOMAIN}/product/${query}`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getAllProducts() {
  return fetch("https://alterx-be.onrender.com/product")
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}
