import React from "react";
import Carousel from "../components/common/Carousel";
import AboutCom from "../components/about/AboutCom";
import OurServices from "../components/home/OurServices";

function Home() {
  return (
    <div>
      <Carousel />
      <AboutCom />
      <OurServices />
    </div>
  );
}

export default Home;
