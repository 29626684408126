import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "../../styles/components/contact/contactInput.module.css";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

function ContactInput() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_CONTACT_KEY,
        data,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        } 
      )
      .then(() => {
        reset();
        toast.success("Email sent successfully");
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Name:</label>
            <input
              {...register("name", {
                required: "Name is required",
                minLength: {
                  value: 2,
                  message: "Name should be at least 2 characters",
                },
              })}
              type="text"
              id="name"
            />
            {errors.name && (
              <p className={styles.error}>{errors.name.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email:</label>
            <input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              type="email"
              id="email"
            />
            {errors.email && (
              <p className={styles.error}>{errors.email.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="number">Phone Number:</label>
            <input
              {...register("number", {
                required: "Phone number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Phone number must be exactly 10 digits",
                },
              })}
              type="text" // Changed from "number" to "text" for better validation
              id="number"
            />
            {errors.number && (
              <p className={styles.error}>{errors.number.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="message">Message:</label>
            <textarea
              {...register("message", {
                required: "Message is required",
                minLength: {
                  value: 10,
                  message: "Message should be at least 10 characters",
                },
              })}
              id="message"
            />
            {errors.message && (
              <p className={styles.error}>{errors.message.message}</p>
            )}
          </div>
          <div className={styles.formGroup}>
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Sending..." : "Send"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactInput;
