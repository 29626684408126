import React from "react";
import styles from "../../styles/components/about/aboutCom.module.css";
import WhyUs from "./WhyUs";

function AboutCom() {
  return (
    <div className={styles.container}>
      <div className={styles.aboutCom}>
        <div className={styles.descCon}>
          <h3>About Us</h3>
          <hr color="#FBBD02" style={{ width: "20%" }} />
          <div className={styles.descPara}>
            <br />
            <p>
              Founded in 2023, ALTERx PHARMACEUTICALS PRIVATE LIMITED aims to
              bridge healthcare gaps globally.
            </p>
            <p>
              With decades of experience, we consistently exceed stringent
              quality standards.
            </p>
            <p>
              Our cutting-edge manufacturing facilities and skilled team ensure
              safe and effective pharmaceutical solutions for communities
              worldwide.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.counterCom}>
        <WhyUs />
      </div>
    </div>
  );
}

export default AboutCom;
