import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function AdminRoute() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      toast.error("Your session has expired. Please log in again.");
      navigate("/login");
    }
  };

  return token ? <Outlet context={{ handleApiError }} /> : null;
}

export default AdminRoute;
