import React from "react";
import styles from "../../styles/components/common/imgLoader.module.css";

function ImgLoader() {
  return (
    <div className={styles.container}>
      <div className={styles.imgCon}>
        <img
          src="https://res.cloudinary.com/dlbflgtdr/image/upload/v1722229864/alterx/ssum7oqx8jahiwgytilw.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default ImgLoader;
