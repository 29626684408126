import React from "react";
import styles from "../../styles/components/home/ourServices.module.css";
import { Link } from "react-router-dom";
import screenTop from "../../utils/scrollTop";

function OurServices() {
  return (
    <div className={styles.container}>
      <div className={styles.goToContact}>
        <div className={styles.descContact}>
          <p>contact us & learn more about us</p>
          <h3>Explore our full range of products.</h3>
        </div>
        <div className={styles.btnCon}>
          <button>
            <Link to="/contact" onClick={screenTop}>
              Contact Us
            </Link>
          </button>
          <button>
            <Link to="/allProducts" onClick={screenTop}>
              All Products
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
