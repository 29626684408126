import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../styles/layout/navbar.module.css";
import scrollTop from "../utils/scrollTop";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    scrollTop();
    setIsMenuOpen(false);
  };

  const isActive = (path) => {
    return location.pathname === path ? styles.active : "";
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navContainer}>
        <Link to="/" className={styles.logoLink}>
          <img
            src="/images/logo.png"
            alt="Company Logo"
            className={styles.logo}
          />
        </Link>

        <div
          className={`${styles.menuToggle} ${isMenuOpen ? styles.active : ""}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span className={styles.menuIcon}></span>
        </div>

        <ul className={`${styles.navMenu} ${isMenuOpen ? styles.active : ""}`}>
          <li>
            <Link to="/" className={isActive("/")} onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className={isActive("/about")}
              onClick={closeMenu}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/allProducts"
              className={isActive("/allProducts")}
              onClick={closeMenu}
            >
              All Products
            </Link>
          </li>
          <li>
            <Link
              to="/career"
              className={isActive("/career")}
              onClick={closeMenu}
            >
              Career
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={isActive("/contact")}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
