import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/pages/pageNotFound.module.css";

function PageNotFound() {
  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/");
  }
  return (
    <div className={styles.container}>
      <p>We are sorry, page not found.</p>
      <p>Error 404.</p>
      <button onClick={handleNavigate}>Back to homepage</button>
    </div>
  );
}

export default PageNotFound;
