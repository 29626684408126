import axios from "axios";

const uploadImage = async (file) => {
  try {
    if (file.type === "application/pdf") {
      throw new Error("PDF files are not allowed");
    }

    const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/auto/upload`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "alterx");
    formData.append("resource_type", "auto");

    const response = await axios.post(cloudinaryUrl, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error(error.message || "Failed to upload file");
  }
};

export default uploadImage;
