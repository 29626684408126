import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminRoute from "./AdminRoute";
import Layout from "./layout/Layout";
import About from "./pages/About";
import AdminPanel from "./pages/admin/AdminPanel";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AllProducts from "./pages/products/AllProducts";
import PageNotFound from "./pages/PageNotFound";
import DetailsPage from "./pages/DetailsPage";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/career" element={<Career />} />
          <Route path="/allProducts" element={<AllProducts />} />
          <Route path="/allProducts/:id" element={<DetailsPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
          {/* admin panel */}
          <Route element={<AdminRoute />}>
            <Route path="/admin" element={<AdminPanel />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
