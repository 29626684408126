import React, { useEffect, useState } from "react";
import styles from "../styles/pages/detailPage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { searchDetails } from "../services/apiServices";

function DetailsPage() {
  const [detail, setDetail] = useState(null);
  const params = useParams();

  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  function downloadPdf(detail) {
    window.open(detail.pdf.path);
  }

  useEffect(() => {
    searchDetails(params.id)
      .then((data) => {
        setDetail(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  if (!detail) return null;

  return (
    <div className={styles.container}>
      <div className={styles.imgCon}>
        <img src={JSON.parse(detail.images[0])[0]} alt="" />
      </div>
      <div className={styles.desc}>
        <p>{detail.name}</p>
        <div className={styles.btnCon}>
          {detail?.pdf?.path && (
            <button
              onClick={() => {
                downloadPdf(detail);
              }}
            >
              More Info
            </button>
          )}
          <button onClick={handleBack}>Back</button>
        </div>
      </div>
    </div>
  );
}

export default DetailsPage;
