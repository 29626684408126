import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getAllProducts } from "../../services/apiServices";
import styles from "../../styles/components/common/carousel.module.css";
import ImgLoader from "./ImgLoader";

function NextArrow({ onClick }) {
  return (
    <button className={`${styles.arrow} ${styles.arrowNext}`} onClick={onClick}>
      <i className="fa-solid fa-angle-right"></i>
    </button>
  );
}

function PrevArrow({ onClick }) {
  return (
    <button className={`${styles.arrow} ${styles.arrowPrev}`} onClick={onClick}>
      <i className="fa-solid fa-angle-left"></i>
    </button>
  );
}

function Carousel() {
  var settings = {
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    dots: true,
  };

  const [allProducts, setAllProducts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllProducts()
      .then((data) => {
        setAllProducts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (loading) return <ImgLoader />;
  const images = allProducts?.map((img) => {
    return JSON.parse(img.images);
  });

  if (!images) return null;
  return (
    <Slider {...settings}>
      {images.map((src, index) => (
        <div key={index} className={styles.mainContainer}>
          <img src={src} alt={`img${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
}

export default Carousel;
