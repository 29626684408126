import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/pages/products/allProducts.module.css";

function ProductCard({ product }) {
  const navigate = useNavigate();

  console.log("product", product._id);
  console.log("product", JSON.parse(product.images[0]));

  function handleDEtailsPage() {
    navigate(`/allProducts/${product._id}`);
  }

  return (
    <div
      className={styles.container}
      key={product._id}
      onClick={handleDEtailsPage}
    >
      <div className={styles.imgCon}>
        <img src={JSON.parse(product.images[0])[0]} alt={product.name} />
      </div>
      <div className={styles.details}>
        <p>{product.name}</p>
      </div>
    </div>
  );
}

export default ProductCard;
