import React from "react";
import styles from "../styles/layout/footer.module.css";
import { Link } from "react-router-dom";
import scrollTop from "../utils/scrollTop";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerSection}>
          <h3>Headquarters</h3>
          <div className={styles.contactInfo}>
            <p>
              <i className="fa-solid fa-location-arrow"></i> 33, Gokuldham,
              Ankleshwar, Gujarat, India 393010
            </p>
            <p className={styles.emailLink}>
              <Link to="mailto:info@alterx.in">
                <i className="fa-solid fa-envelope"></i> info@alterx.in
              </Link>
            </p>
            {/* <p>
              <i className="fa-solid fa-phone"></i> +91 33 24549650
            </p> */}
          </div>
        </div>
        <div className={styles.footerSection}>
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to="/" onClick={scrollTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={scrollTop}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/allProducts" onClick={scrollTop}>
                All Products
              </Link>
            </li>
            <li>
              <Link to="/career" onClick={scrollTop}>
                Career
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={scrollTop}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/termsAndConditions" onClick={scrollTop}>
              Terms and conditions
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.footerSection}>
          <h3>Connect With Us</h3>
          <div className={styles.socialIcons}>
            <Link to="#" aria-label="Facebook" onClick={scrollTop}>
              <i className="fa-brands fa-facebook"></i>
            </Link>

            <Link to="#" aria-label="LinkedIn" onClick={scrollTop}>
              <i className="fa-brands fa-linkedin"></i>
            </Link>

            <Link to="#" aria-label="Twitter" onClick={scrollTop}>
              <i className="fa-brands fa-twitter"></i>
            </Link>

            <Link to="#" aria-label="Instagram" onClick={scrollTop}>
              <i className="fa-brands fa-instagram"></i>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
